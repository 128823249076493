@import url("https://fonts.cdnfonts.com/css/amazon-ember");

.main-container {
  font-family: "Amazon Ember", sans-serif;
  background: #eaeded;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.main-banner {
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-grid-container {
  position: relative;
  display: flex;
  /* remove justify-content when adding more grid boxes */
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.grid-box {
  position: relative;
  z-index: 0;
  top: -180px;
  height: 350px;
  padding: 20px 10px 0;
  margin: 25px 10px;
  background-color: white;
  width: 320px;
}

.grid-image-label {
  font-size: 12px;
}

.grid-box-label {
  font-weight: bold;
  font-size: 21px;
}

.sign-in-box {
  height: 150px !important;
}

.grid-box-image-container {
  height: 275px;
  margin-top: 22px;
  margin-bottom: 44px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.grid-box-image {
  width: 40%;
  height: 40%;
  /* position: relative; */
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-button {
  background-color: transparent;
  border: none;
}

.carousel-button-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 25%;
  width: 100%;
}

.carousel-image {
  position: relative;
  z-index: -1;
  user-select: none;
}

.carousel-slide {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #eaeded);
}

.carousel-button-image {
  height: 50px;
}
