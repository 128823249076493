@import url("https://fonts.cdnfonts.com/css/amazon-ember");

div {
    font-family: "Amazon Ember", sans-serif;
}

.cart-item-container {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
}

.cart-item-image-container {
    margin-left: 20px;
    width: 15%;
    max-height: 5%;
    max-height: 25%;
    text-align: center;
}

.item-center-container {
    width: 80%;
}

.cart-eligible {
    font-size: 12px;
}

.cart-eligible > span {
    font-size: 14px;
    color: #007185;
}

.cart-item-name {
    font-size: 18px;
    margin-bottom: 10px;
}

.cart-stock-label {
    color: #007600;
    font-size: 12px;
    padding-bottom: 5px;
}

.cart-item-price {
    font-weight: bold;
}

.cart-count-select {
    font-family: "Amazon Ember", sans-serif;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: transparent;
    border-radius: 7px;
    background: #f0f2f2;
    box-shadow: 0 2px 10px rgba(15, 17, 17, 0.394);
    padding: 3px 4px;
    font-size: 12px;
    cursor: pointer;
    word-spacing: 3px;
}

.quantity-section {
    display: flex;
    align-items: center;
}

.quantity {
    margin-right: 10px!important;
}

.cart-item-delete-button {
    background: 0 0;
    border: 0;
    color: #007185;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.cart-item-delete-container {
    border-left: 1px solid #cdcccc;
    padding: 0px 7px;
}

.quantity-section > div {
    margin: 0px 5px;
}

.cart-item-image {
    width: 85%;
}

.item-name-container {
    width: 60%;
    margin-right: 15%;
}