@import url("https://fonts.cdnfonts.com/css/amazon-ember");

.product-item-cell {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: 400px;
  width: 275px;
  margin-bottom: 15px;
  font-family: "Amazon Ember", sans-serif;
}

.product-link {
  text-decoration: none;
  color: black;
  font-family: "Amazon Ember", sans-serif;
  margin-left: 7px;
}

.product-item-rating {
  margin-left: 7px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.product-item-rating-val {
  margin-top: 2px;
  font-size: 14px;
  line-height: 20px;
  margin-right: 4px;
}

.product-ratings-length {
  font-size: 14px;
  line-height: 20px;
  color: #007185;
  margin-left: 5px;
}

.product-item-base {
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;
  margin: 10px;
  width: 100%;
  height: 100%;
}

.product-item-image {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  height: 200px;
}

.product-overlay {
  position: relative;
  max-height: 200px;
  object-fit: contain;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.price-symbol {
  position: relative;
  top: -0.75em;
  font-size: 13px;
}

.price-main-price {
  font-size: 28px;
}

.product-item-name {
  width: fit-content;
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-item-name:hover {
  cursor: pointer;
  color: #c7511f;
}

.product-overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.03;
  pointer-events: none;
}
