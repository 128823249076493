@import url("https://fonts.cdnfonts.com/css/amazon-ember");

.display-show-container {
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  font-family: "Amazon Ember", sans-serif;
}

.product-show-top-container {
  display: flex;
  width: 100%;
}

.left-container {
  margin-left: 10%;
  width: 20%;
  height: 20%;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.center-container {
  margin-left: 7%;
  margin-right: 8%;
  font-size: 25px;
  width: 30%;
}

.right-container {
  margin-left: 20px;
  float: right;
  height: 500px;
  width: 200px;
  border: 1px #d5d9d9 solid;
  border-radius: 8px 8px 0 0;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
}

.prime-label {
  position: relative;
  color: #565959;
  font-size: 14px;
  bottom: 4px;
}

.points-back {
  font-size: 14px;
  line-height: 18px;
}

.product-linked-link {
  text-decoration: none;
  color: #007185;
}

.product-linked-link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #c7511f;
}

.points-back > span {
  color: #007185;
  font-weight: 700;
}

.product-price {
  margin-bottom: 10px;
}

.delivery-date {
  color: black;
  font-weight: bold;
}

.delivery-label {
  line-height: 18px;
}

.stock-label {
  color: #007600;
  margin-bottom: 10px;
}

.return-label,
.returnable-label {
  color: #007185;
}

.fast-delivery-hour {
  color: #007600;
}

.policy-label {
  margin-top: 15px;
  line-height: 18px;
  font-weight: light;
  font-size: 14px;
}

.fastest-delivery {
  line-height: 18px;
}

.return-label,
.delivery-label,
.fastest-delivery {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

input[type="submit"] {
  cursor: pointer;
}

.price-label,
.price-amt {
  display: none;
}

.review-section-container {
  margin-bottom: 15px;
}

.count-select {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: transparent;
  border-radius: 7px;
  background: #f0f2f2;
  box-shadow: 0 2px 10px rgba(15, 17, 17, 0.394);
  padding: 1px 4px;
  font-size: 12px;
  cursor: pointer;
  word-spacing: 3px;
}

.count-select:hover {
  background: #bbbbbbb9;
}

.count-select > option {
  border-color: #d5d9d9;
  background-color: #edfdff;
  border-left-color: #007185;
}
.product-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

form > input.product-add-btn {
  margin-top: 15px;
  background: #ffd814;
  border-color: #fcd200;
  border-radius: 19px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  padding: 7px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
}

.product-add-btn:hover {
  background: #f7ca00;
  border-color: #f2c200;
  box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
}

.secure-label {
  font-size: 14px;
  color: #007185;
  line-height: 20px;
  margin-bottom: 10px;
}

.misc-label-ship,
.misc-label-sold {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin: 3px;
}

.misc-link {
  padding-right: 10px;
}

hr {
  background-color: transparent;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-top: 1px solid #e7e7e7;
  display: block;
  height: 1px;
  line-height: 19px;
  margin-bottom: 14px;
  margin-top: 10px;
}

.price-label {
  position: relative;
  bottom: 5px;
  font-size: 14px;
  padding-right: 5px;
  color: rgba(0, 0, 0, 0.67);
}

.price-amt {
  font-size: 18px;
  color: #b12704;
  text-rendering: optimizeLegibility;
  line-height: 24px;
}

.about-label {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}

.about-list {
  margin-left: 18px;
  font-size: 14px;
  font-weight: light;
  list-style: disc;
}

.ratings-length {
  font-size: 14px;
  line-height: 20px;
  color: #007185;
}
