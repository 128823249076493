@import url("https://fonts.cdnfonts.com/css/amazon-ember");

#root {
  width: 100%;
}

.nav-bar {
  background-color: #131921;
  height: 100%;
  max-height: 70px;
  display: flex;
  justify-content: space-between;
  font-family: "Amazon Ember", sans-serif;
  position: relative;
  z-index: 2;
  white-space: nowrap;
}

.nav-left {
  width: 25%;
  min-width: 300px;
  /* text-align: center; */
}

.animated-digimon {
  margin-right: 10px;
  height: 50%;
  transform: scaleX(-1);
}

.nav-center {
  display: flex;
  align-items: center;
  width: 30%;
  flex: 1 1 auto;
  min-width: 600px;
}

.nav-search {
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-logo {
  margin-left: 5px;
  object-fit: contain;
  width: 125px;
}

.nav-search-field {
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  z-index: 1;
  border-style: none;
}

.nav-search-button {
  background: url("../../assets/search-button.png") no-repeat scroll 0 0
    transparent;
  background-color: #febd69;
  background-size: 60%;
  background-position: 65% 50%;
  color: transparent;
  border-radius: 5px;
  position: relative;
  right: 7px;
  border-style: none;
  border: none;
  cursor: pointer;
}

.nav-right {
  color: white;
  display: flex;
  justify-content: center;
}

.cart-link {
  display: flex;
  align-items: center;
}

.nav-right-container,
.cart-link {
  margin: auto 5px;
  cursor: pointer;
  padding: 10px 15px;
  border: 1px solid transparent;
  height: 50%;
  max-height: 70px;
  overflow: hidden;
  white-space: nowrap;
}

.cart-link > .nav-right-container {
  width: inherit;
  height: inherit;
}

.nav-right-container:hover,
.cart-link:hover {
  border: 1px solid white;
}

.nav-right-container:last-child:hover {
  border: 1px solid transparent !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.nav-right-container > span,
.nav-right-container > a > span,
.nav-bar-dropdown a span {
  font-size: 12px;
}

.nav-right-container > p,
.nav-right-container > a > p,
.nav-bar-dropdown a p {
  font-weight: bold;
}

.nav-right > ul {
  display: flex;
}

.nav-right > ul > li {
  font-size: 18px;
  margin: 5px auto;
}

.nav-bar-dropdown {
  float: left;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.nav-bar-dropdown-content {
  color: black;
  display: none;
  position: absolute;
  margin-top: 5px;
  top: 55px;
  right: 150px;
  background-color: white;
  min-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  height: 200px;
  cursor: default;
  border-radius: 5px;
  padding: 10px 10px;
  justify-content: space-between;
}

.dropdown-gif-image {
  height: 200px;
}

.dropdown-signin-button,
.dropdown-logout-button {
  font-size: 13px;
  margin-bottom: 13px;
  width: 100%;
  background-image: linear-gradient(to bottom, #f8e3ad, #eeba37);
  border-color: #c89411 #b0820f #99710d;
  color: #111;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-align: center;
  text-decoration: none;
  height: 33px;
  border-radius: 2px;
}

.dropdown-signin-button:hover,
.dropdown-logout-button:hover {
  background: linear-gradient(to bottom, #f6da95, #ecb21f);
}

.dropdown-right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.dropdown-signup-label {
  text-align: center;
  font-size: 11px;
  color: #333;
  margin: 2px 0 9px 0;
}

.dropdown-signup-link {
  font-size: 11px;
  text-decoration: none;
  color: #05a;
  display: inline;
}

.nav-cart-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.nav-cart {
  height: 32px;
  width: 42px;
}

.nav-cart-label {
  font-weight: bold;
  position: relative;
  bottom: 3px;
}

.nav-cart-count {
  font-size: 16px;
  color: #f08804;
  font-weight: bold;
  position: absolute;
  right: 63px;
  top: 15px;
  text-align: center;
}

.disabled {
  color: red;
}

.dropdown-signup-link:hover {
  text-decoration: underline;
  color: #e47911;
  display: inline;
}

/* .nav-bar-dropdown:hover .nav-bar-dropdown-content {
  display: block;
} */
/* 
.nav-right:first-child:hover .nav-bar-dropdown .nav-bar-dropdown-content {
  display: block;
} */

.nav-bar-dropdown-content::after {
  content: "";
  display: block;
  position: absolute;
  left: 65%;
  top: -5px;
  border-top: none;
  border-bottom: 5px solid white;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.dropdown-background {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.449);
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
