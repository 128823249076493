@import url("https://fonts.cdnfonts.com/css/amazon-ember");

.checkout-confirm-container {
  margin-top: 2%;
  width: 60%;
  max-width: 80%;
  background-color: white;
  padding: 25px 25px;
  margin-bottom: 5%;
  padding-bottom: 3%;
  height: fit-content;
  display: flex;
  font-family: "Amazon Ember", sans-serif;
  align-items: center;
}

.checkout-image-container {
  width: 60%;
}

.checkout-image {
  height: 350px;
}
.checkout-label-container {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout-label {
  color: #067d62;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  white-space: nowrap;
  margin-left: 1px;
}

.confirmed-image {
  width: 6%;
}

.checkout-misc {
  margin-top: 5px;
  text-align: center;
}

.secret-message {
  font-size: 10px;
  color: rgba(104, 99, 99, 0.491);
}

.self-plug {
  margin-top: 10px;
  font-size: 11px;
}
