@import url("https://fonts.cdnfonts.com/css/amazon-ember");

.cart-label {
  font-size: 28px;
}

.cart-container,
.empty-cart-container {
  margin-top: 2%;
  width: 60%;
  max-width: 80%;
  background-color: white;
  padding: 25px 25px;
  padding-bottom: 3%;
  height: fit-content;
}

.empty-cart-container {
  display: flex;
  align-items: center;
}

.cart-empty-image-container {
  width: 50%;
  margin-right: 10px;
}

.empty-cart-image {
  width: 100%;
  object-fit: contain;
}

.cart-empty-label {
  font-size: 24px;
  font-weight: 700;
}

.checkout-container {
  margin-top: 2%;
  background-color: white;
  height: 78px;
  padding: 20px 20px 15px 20px;
  width: 300px;
}

.checkout-container > .sub-total-container {
  float: none;
}

.background-container {
  background: #eaeded;
}

.cart-background {
  font-family: "Amazon Ember", sans-serif;
  background: #eaeded;
  height: 75vh;
  width: 100%;
  padding: 1px;
  display: flex;
  justify-content: space-evenly;
}

.cart-form,
.form-link {
  font-size: 12px;
}

.form-link {
  color: #007185;
}

.cart-eligible {
  margin-bottom: 5px;
}

.sub-total-container {
  float: right;
  font-size: 18px;
}

.sub-total-amt {
  font-weight: bold;
}

.cart-price-label {
  text-align: end;
  font-size: 14px;
  color: #565959;
  margin-right: 3px;
}

.top-border {
  margin-top: 5px;
}

form > input.checkout-btn {
  margin-top: 15px;
  background: #ffd814;
  border-color: #fcd200;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  padding: 7px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
}

.checkout-btn:hover {
  background: #f7ca00;
  border-color: #f2c200;
  box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
}
