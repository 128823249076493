@import url("https://fonts.cdnfonts.com/css/amazon-ember");

.product-review {
  font-family: "Amazon Ember", sans-serif;
  min-width: 998px;
  padding: 14px 18px 18px;
  margin: 0 auto;
  text-rendering: optimizeLegibility;
}

.customer-review-label {
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    padding-bottom: 4px;
}