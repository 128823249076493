.top-rated-carousel-container {
  font-family: "Amazon Ember", sans-serif;
  position: relative;
  background: white;
  padding: 20px 20px;
  width: 90%;
  height: 250px;
  top: -125px;
  margin: 0 auto;
}

.top-rated-carousel-provider {
  height: 100%;
  width: 100%;
}

.top-rated-label {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 5px;
}

.top-rated-carousel-button-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 40%;
  width: 100%;
}

.top-rated-button {
  background: white;
  border: none;
  height: 50%;
  padding: 25px 3px;
  box-shadow: 0 1px 3px #888;
}

.tr-next {
  position: absolute;
  right: 0;
  bottom: 50px;
}

.tr-back {
  position: absolute;
  left: 0;
  bottom: 50px;
}

.top-rated-button-image {
  width: 40%;
}

.top-rated-carousel {
  height: 200px;
  padding-bottom: 0px!important;
}

.top-rated-carousel-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-rated-carousel-slider {
  height: 90%;
  margin-left: 25px;
  cursor: pointer;
}

.top-rated-carousel-slider-tray {
  text-align: center;
  height: 100%;
}
.top-rated-carousel-slider-tray-wrap {
  height: 100%;
}

.top-rated-image {
  width: 75%;
  height: auto;
}


.next {
  clip: rect(-10px, 45px, 110px, -10px);
}
